import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import FurtherReading from "../components/furtherReading"

const WigsForBlackWomen = () => (
  <Layout>
    <SEO
      title="Wigs For Black Women"
      description="The surprising reasons a wig might be exactly what you need. How a wig can help you on the way to beautiful natural hair"
    />
    <h1>Wigs For Black Women</h1>
    <h2 style={{color:"Orchid"}}>The surprising reasons a wig might be exactly what you need</h2>
    <p>As beautiful as natural hair is, we can all agree that it takes a lot of dedication and time to get right. It can be a pain when our natural hair doesn’t behave like we want it to. It could be from a change of season, diet or for no apparent reason.</p>

    <div style={{ textAlign: "center" }}>
      <img
        src="https://i.imgur.com/7yR92WG.jpg"
        alt="wigs for black women header"
        style={{marginBottom: "5px"}}
      />
      <p style={{fontSize: "0.9rem", textAlign:"left", color:"grey"}}>Photo credit: <a
        href="https://unsplash.com/@j_erhunse"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration:"none", color:"inherit" }}
      >Jeffery Erhunse</a></p>
    </div>

    <p>If only we had something that could save us the time and trouble when we want a rest. Well, we do and that’s where wigs come in.</p>
    <p>In the past, the idea of wearing a wig was something people were embarrassed about. Most people kept it quiet because it would give the impression of there being something wrong or the person being sick. A lot of people turned to a potentially damaging hair treatment like relaxer.</p>
    <p>Luckily, modern times have changed all of that and wearing a wig is now socially acceptable. You can change your wig from curly to straight in the same week and people in your life might say, “nice hair” or “that really suits you”. No question of “is that your hair?”.</p>

    <h2>Why To Choose A Wig</h2>
    <p>I mentioned above about convenience being the main reason for wearing for wearing a wig but let’s quickly go over the others.</p>

    <h3 style={{color:"Orchid"}}>Protective Styling</h3>
    <p>A huge benefit of wearing a wig is that it is a protective style. This means that it doesn’t stress your hair and gives it a great chance grow longer much faster. You are protecting your hair from the environment which can damage it and cause it to break sooner than it should.</p>
    <h3 style={{color:"Orchid"}}>Just Need a Change</h3>
    <p>It’s been a while since you experimented, if ever, so it’s time to try something new. You might not be adventurous enough or want to no risk damaging your natural hair so a wig be the perfect choice.</p>
    <h3 style={{color:"Orchid"}}>After The Chop</h3>
    <p>In the natural hair community, we often talk about the big chop to get a fresh start with growing out our hair. Well, some of us are not as brave as others to go out with really short hair. A wig gives you the opportunity to get all the benefits of a chop while giving your hair a chance to grow to a length you are comfortable with.</p>

    <h2>Wig Hair Styles</h2>
    <p>It might come as no surprise but wigs come in many hair styles. You won’t find anywhere a lot of your scalp is exposed like braids or cornrow because that will look strange but anything where the hair is worn down is out there to be found.</p>
    <p>You can get loose curls, tight curls to replicate afro hair, straight. You can even re-style a wig by straightening it or curling it. Colouring the hair is also quite straight forward but we’ll get onto that later.</p>

    <h2>Wig Material Types</h2>
    <p>Wigs come in various types. Human, Animal and Synthetic.</p>

    <h3>Synthetic Hair</h3>
    <p>Synthetic wigs are the cheapest and lowest quality of the three. Although the quality does vary quite a lot so you can get synthetic wigs that look good to begin with but can fade quickly and certainly won’t last a long time under heavy use.</p>
    <p> A single wig might be cheaper to buy but could end up costing more in the long run because you could have bought 4 or 5 in the time a single human hair wig would have lasted.</p>
    <h4>Who are synthetic wigs for?</h4>
    <p>Someone who doesn’t want to spend much and will not be wearing the wig often. You might only want to wear it for going on quick trips and it could be a backup for your more expensive wig. It’s also a low cost way to try out a colour or style before you fully commit.</p>

    <h3>Human Hair</h3>
    <p>Human hair wigs are the most expensive and desirable, people usually mention Brazilian, Peruvian & Indian for quality. Don’t forget you can get human hair afro wigs, it’s usually called virgin hair. They behave just like someone’s hair because they are exactly that. They look completely natural as long as they are fitted properly. Synthetic wigs can look still and costume like, human hair wigs don’t have that problem.</p>
    <p>I mentioned colouring and styling earlier, human hair wigs can be curled, straightened, highlighted, coloured completely. They aren’t invincible though so you still have to be careful not to over process them but they still look great if you look after them. That being said, stories of human hair wigs lasting 3+ years are common.</p>
    <h4>Who are Human hair wigs for?</h4>
    <p>Someone who has the money and wants to spend more to get the get the best look possible. You want to wear the wig on a regular basis, to work and everyday life activities. If you wanted to give the impression that the hair is actually yours and want to avoid questions about if it’s yours or not then a human hair wig is the best option.</p>

    <h3>Animal hair</h3>
    <p>Animal hair is similar to our own so naturally comes in different textures. It is usually mixed to improve the look so it’s rare to find a wig with a single animal used throughout. It is also the most sustainable because it occurs naturally and is a by-product of raising animals. In terms of price, animal hair sits in the middle of synthetic and human. It’s in the middle in terms of quality too.</p>
    <h4>Who is animal hair for?</h4>
    <p>A person who doesn’t mind the idea of wearing animal hair. Someone who wants something that looks better than a synthetic wig but does not want to spend more for human hair.</p>

    <h2>Where to get your wig?</h2>
    <h3 style={{color:"Orchid"}}>Quick and convenient</h3>
    <p>Amazon is the easiest if it’s available where you are. All the options I mentioned above are listed and at all price ranges.</p>
    <p>Alternatively, you can go and browse in your local hair shop, they usually have a few options and can order some in if you ask them.</p>

    <h3 style={{color:"Orchid"}}>Cheap from China</h3>
    <p>AliExpress. You will have to wait a while for delivery depending on which delivery option you choose. From about 4 days for the most expensive to a few weeks for the cheapest.</p>

    <h3 style={{color:"Orchid"}}>Ordering in Bulk</h3>
    <p>Alibaba. Similar to the above but there are options to buy multiple wigs at once at a cheaper price. Good if you want to sell wigs or you’re ordering for a big group of people.</p>

    <h3 style={{color:"Orchid"}}>Make it yourself</h3>
    <p>Buy weaving hair bundles, a type of closure, a wig cap, some thread, needles and if you have the skill, you can make it yourself. You might need a mannequin head too. There are plenty of tutorials online on how to do it if you aren’t sure.</p>

    <h2>Under the wig</h2>
    <p>Having a nice wig isn’t just about buying one and putting it on, what happens underneath it is important too. I mean the styling of your own hair will affect the way the wig sits and looks.</p>
    <p>Ideally, you want the wig to sit as close to your head as possible. Wigs have their own thickness and combined with the thickness of your own hair, there should be a balance. If you have short hair already you need to secure the wig appropriately an go on.</p>
    <p>If you have longer hair, you will need to braid you hair in smaller sections and as flat as possible. Not 2 or 4 braids. More like a minimum of 8. If wearing a wig is a long term thing, it might be useful to learn how to braid your own hair if you don’t know how to already. It could save you some time and money.</p>
    <p>Secure points to attach the wig is important too. It should be attached properly so it doesn’t move around during the day or fly off in the wind or a small tug by or on something. Braiding you hair gives more options for securing the wig tightly.</p>

    <h2>The closure</h2>
    <p>Wigs come in different sorts of closures. Each one has it’s pros and cons so it’s worth doing a bit of research to see which one you want to try. There are closures and frontals. These determine how the wig is fixed  and how it looks at the edges. You can also get different parting closures. They have pre-set parts in the hair so you can have side parts or middle parts.</p>

    <h2>Other Styles</h2>
    <p>If you are not sure about wigs yet and want to check out another protective style. We've written a whole article about <Link to="/tips-on-box-braids-for-black-hair/" style={{ textDecoration: "none" }}>tips on box braids</Link> just like this one so check it out.</p>



    {/* <h2>Subtitle</h2> */}

    {/* <a
        href="https://example.com/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        Example Link - for affiliate, add nofollow
      </a> */}



      <FurtherReading
      content={
        <div>
        <p>
            <Link to="/afro-hair-products/" style={{ textDecoration: "none", color:"Orchid", fontWeight:"600" }}>
             The Best Afro Hair Products
            </Link>{" "}
            - The complete list of products you need for every step in your hair care routine...
          </p>  
        <p>
            <Link to="/14-tips-on-how-to-grow-afro-hair/" style={{ textDecoration: "none", color:"Orchid", fontWeight:"600" }}>
            14 tips on how to grow afro hair
            </Link>{" "}
            - Growing afro hair can be one of the most annoying things in the world, the good news is that growing natural doesn't have to be hard. Read our 14 tips to make your life easier here...
          </p> 
          <p>
            <Link to="/afro-hair-trends-2021/" style={{ textDecoration: "none", color:"Orchid", fontWeight:"600" }}>
            Afro Hair Trends 2021
            </Link>{" "}
            - A look into the popularity of black hair styles, trends and techniques for the past year. Find out how your searches compare to other people around the world....
          </p>                 
        </div>
      }
    />

    <Link to="/" style={{ textDecoration: "none" }}>
      Go back to the homepage
    </Link>
  </Layout>
)

export default WigsForBlackWomen
